<template>
	<div>
		<!--container : 구매정보-->
		<div class="container wrap_dashboard">
			<h2>{{ headerInfo.pageOrderInfoTitle }}</h2>
			<div class="wrap_list_dashboard">
				<ul class="com_list_dashboard" v-if="isSAAS === undefined"></ul>
				<ul class="com_list_dashboard" v-else-if="isSAAS">
					<li>
						<span class="tit"
							><span class="txt"
								><template v-if="headerInfo.payType == 'TRANSPORT'">구매 신청일</template
								><template v-else>결제일</template></span
							></span
						>
						<span class="data"
							><span class="txt"
								><template v-if="headerInfo.paymentDate">{{ headerInfo.paymentDate | convertYYMMDD }}</template>
								<template v-else>-</template></span
							></span
						>
					</li>
					<li>
						<span class="tit"><span class="txt">결제자</span></span>
						<span class="data">
							<span class="txt"
								><template v-if="headerInfo.paymenter">{{ headerInfo.paymenter }}</template>
								<template v-else>-</template></span
							>
						</span>
					</li>
					<li>
						<span class="tit"><span class="txt">결제 방법</span></span>
						<span class="data"
							><span class="txt">{{ headerInfo.payMethod }}</span></span
						>
					</li>
					<li>
						<span class="tit"><span class="txt">주문 상태</span></span>
						<span class="data">
							<span v-if="headerInfo.orderStatus == 'WAIT'" class="label_state inactive">결제대기</span>
							<span v-if="headerInfo.orderStatus == 'COMPLETE'" class="label_state inactive">결제완료</span>
							<span v-if="headerInfo.orderStatus == 'DROP'" class="label_state abnormal">주문취소</span>
							<span v-if="headerInfo.orderStatus == 'CANCEL'" class="label_state abnormal">전체환불</span>
							<span v-if="headerInfo.orderStatus == 'PART_CANCEL'" class="label_state abnormal">부분환불</span>

							<button class="fn" v-if="headerInfo.orderStatus == 'WAIT'" v-on:click="openConvertPaymethod()">
								<span class="ic_set ic_sync"></span><span class="txt">결제 방법 변경</span>
							</button>

							<button
								class="fn"
								v-if="
									(headerInfo.payType == 'CREDITCARD' || headerInfo.payType == 'AUTO_CREDITCARD') &&
									headerInfo.orderStatus == 'COMPLETE'
								"
								v-on:click="popupSales()"
							>
								<span class="ic_set ic_info"></span>
								<span class="txt">매출전표</span>
							</button>
						</span>
					</li>
				</ul>
				<ul class="com_list_dashboard" v-else>
					<li>
						<span class="tit">
							<span class="txt">주문 종류</span>
						</span>
						<span class="data">
							<span class="txt" v-if="headerInfo.orderType == 'FRESH'">신규</span>
							<span class="txt" v-if="headerInfo.orderType == 'ADD'">추가</span>
							<span class="txt" v-if="headerInfo.orderType == 'EXTENSION'">연장</span>
							<span class="txt" v-if="headerInfo.orderType == 'ADD_EXTENSION'">연장추가</span>
							<span class="txt" v-if="headerInfo.orderType == 'CHANGE'">변경</span>
						</span>
					</li>
					<li>
						<span class="tit">
							<span class="txt">계약일</span>
						</span>
						<span class="data">
							<span class="txt">
								<template v-if="headerInfo.contractDate">{{ headerInfo.contractDate | convertYYMMDD }}</template>
								<template v-else>-</template>
							</span>
						</span>
					</li>
					<li>
						<span class="tit">
							<span class="txt">적용 시작일</span>
						</span>
						<span class="data">
							<span class="txt">
								<template v-if="headerInfo.applyDate">{{ headerInfo.applyDate | convertYYMMDD }}</template>
								<template v-else>-</template>
							</span>
						</span>
					</li>
					<li>
						<span class="tit">
							<span class="txt">결제자</span>
						</span>
						<span class="data">
							<span class="txt">
								<template v-if="headerInfo.paymenter">{{ headerInfo.paymenter }}</template>
								<template v-else>-</template>
							</span>
						</span>
					</li>
					<li>
						<span class="tit">
							<span class="txt">결제 방법</span>
						</span>
						<span class="data">
							<span class="txt">{{ headerInfo.payMethod }}</span>
						</span>
					</li>
					<li>
						<span class="tit">
							<span class="txt">계약 상태</span>
						</span>
						<span class="data">
							<span v-if="headerInfo.orderStatus == 'WAIT'" class="label_state inactive">결제대기</span>
							<span v-if="headerInfo.orderStatus == 'COMPLETE'" class="label_state inactive">결제완료</span>
							<span v-if="headerInfo.orderStatus == 'DROP'" class="label_state abnormal">주문취소</span>
							<span v-if="headerInfo.orderStatus == 'CANCEL'" class="label_state abnormal">전체환불</span>
							<span v-if="headerInfo.orderStatus == 'PART_CANCEL'" class="label_state abnormal">부분환불</span>
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="container wrap_dashboard">
			<h2>{{ headerInfo.pageContractTitle }}</h2>
			<!--멤버십 정보-->
			<div class="com_box_membership" v-if="orderInfo">
				<h1>
					{{ getServiceName(orderInfo.serviceType) }}
				</h1>
				<span class="txt sub">Membership</span>
				<span class="com_tag" v-if="orderInfo.baseChannelOrderInfoModel">
					<span class="txt" v-if="orderInfo.baseChannelOrderInfoModel.totalOrderUser > 0">
						사용자 {{ orderInfo.baseChannelOrderInfoModel.totalOrderUser }}인
					</span>
					<span class="txt" v-if="orderInfo.baseChannelOrderInfoModel.totalOrderQuota > 0">
						{{ orderInfo.baseChannelOrderInfoModel.totalOrderQuota }}GB
					</span>
				</span>
			</div>
			<!--걸제금액+환불금액 정보-->
			<div class="wrap_form">
				<div class="com_list">
					<table class="com_tb_data large tb_membership_payment">
						<thead>
							<tr>
								<th class="svc"><span class="txt">서비스명</span></th>
								<th class="add_extension">
									<span class="txt">주문 종류</span>
								</th>
								<th class="item flex"><span class="txt">상품명</span></th>
								<th class="num"><span class="txt">수량</span></th>
								<th class="period"><span class="txt">사용기간</span></th>
								<th class="sum">
									<span class="txt" v-if="isSAAS">결제 금액(원)</span>
									<span class="txt" v-else>계약 금액(원)</span>
								</th>
								<th class="state"><span class="txt">상태</span></th>
							</tr>
						</thead>
						<tbody>
							<template v-for="service in orderData">
								<template v-for="(item, index) in service">
									<tr v-if="item.type == 'main'" :key="item.serviceName + '_' + item.itemName + '_main'">
										<td class="svc" v-if="index == 0" :rowspan="service.length">
											<span v-if="item.serviceName.indexOf('경리회계') > -1" class="ic_svc ic_accounting"></span>
											<span v-if="item.serviceName == '경비처리(무증빙)'" class="ic_svc ic_bizplay"></span>
											<span v-if="item.serviceName == 'TMSe 장비일체형 S'" class="ic_svc ic_tmse_s"></span>
											<span v-if="item.serviceName == 'TMSe 장비일체형 E'" class="ic_svc ic_tmse_e"></span>
											<span v-if="item.serviceName == 'TMSe 장비일체형 T'" class="ic_svc ic_tmse_share"></span>
											<span class="txt">{{ item.serviceName }}</span>

											<span class="com_tag" v-if="item.freeTrialPolicyModel && item.freeTrialPolicyModel.usePolicy">
												<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }}개월 무료체험</span>
											</span>
										</td>
										<td class="add_extension">
											<span class="txt">{{ item.addExtension }}</span>
										</td>
										<td class="item flex">
											<span class="txt">{{ item.itemName }}</span>
										</td>
										<td class="num">
											<span class="txt"> {{ item.count }}</span>
										</td>
										<td class="period">
											<span class="txt">{{ item.usePeriod }}</span>
										</td>
										<td class="sum">
											<span class="txt">{{ item.finalPrice | currency }}원</span><br />
											<span v-if="item.finalPrice < item.originPrice" class="txt line_through"
												>{{ item.originPrice | currency }}원</span
											>
										</td>
										<td class="state">
											<template v-if="item.status == 'CANCELED'">
												<template v-if="item.itemRefund.refundType == 'FULL_REFUND'">
													<span class="label_state abnormal">전체환불</span><br />
													<span
														class="txt"
														v-on:click="
															cancelInfoPopup.itemRefund = item.itemRefund;
															cancelInfoPopup.flag = true;
														"
														><a>{{ item.itemRefund.cancelDate }}</a></span
													>
												</template>
												<template v-if="item.itemRefund.refundType == 'PART_REFUND'">
													<span class="label_state abnormal">부분환불</span><br />
													<span
														class="txt"
														v-on:click="
															cancelInfoPopup.itemRefund = item.itemRefund;
															cancelInfoPopup.flag = true;
														"
														><a>{{ item.itemRefund.cancelDate }}</a></span
													>
												</template>
											</template>
										</td>
									</tr>
									<tr
										v-if="item.type == 'sub'"
										class="sub"
										:key="index + '_' + item.serviceName + '_' + item.itemName + '_sub'"
									>
										<!--@sub data-->
										<td class="add_extension"></td>
										<td class="item flex">
											<span class="ic_set ic_depth"></span>
											<span class="txt">{{ item.itemName }}</span>
										</td>
										<td class="num">{{ item.count }}</td>
										<td class="period"></td>
										<td class="sum">
											<span class="txt">{{ item.finalPrice | currency }}</span>
										</td>
										<td class="state">
											<template v-if="item.status == 'CANCELED'">
												<template v-if="item.itemRefund.refundType == 'FULL_REFUND'">
													<span class="label_state abnormal">전체환불</span><br />
													<span
														class="txt"
														v-on:click="
															cancelInfoPopup.itemRefund = item.itemRefund;
															cancelInfoPopup.flag = true;
														"
														><a>{{ item.itemRefund.cancelDate }}</a></span
													>
												</template>
												<template v-if="item.itemRefund.refundType == 'PART_REFUND'">
													<span class="label_state abnormal">부분환불</span><br />
													<span
														class="txt"
														v-on:click="
															cancelInfoPopup.itemRefund = item.itemRefund;
															cancelInfoPopup.flag = true;
														"
														><a>{{ item.itemRefund.cancelDate }}</a></span
													>
												</template>
												<template v-if="item.itemRefund.refundType == 'NONE'">
													<span class="label_state abnormal">부분환불</span><br />
													<span
														class="txt"
														v-on:click="
															cancelInfoPopup.itemRefund = item.itemRefund;
															cancelInfoPopup.flag = true;
														"
														><a>{{ item.itemRefund.cancelDate }}</a></span
													>
												</template>
											</template>
										</td>
									</tr>
								</template>
							</template>
						</tbody>

						<tbody class="summary">
							<!--<tr>
								<td class="svc" colspan="4">
									<span class="txt">적용 할인 명</span>
								</td>
								<td class="sum">
									<span v-html="discountDesc"></span>
								</td>
								<td>&nbsp;</td>
							</tr>-->
							<tr>
								<td class="svc" colspan="5">
									<span class="txt" v-if="orderInfo && orderInfo.baseInfoModel.orderType == 'CHANGE'"
										>멤버십전환금액</span
									>
									<span class="txt" v-else>상품 금액</span>
								</td>
								<td class="sum">
									<span class="txt">{{ summaryInfo.totAmt | currency }}원</span>
								</td>
								<td>&nbsp;</td>
							</tr>
							<tr>
								<td class="svc" colspan="5">
									<span class="txt">부가세(10%)</span>
								</td>
								<td class="sum">
									<span class="txt">{{ summaryInfo.vatAmt | currency }}원</span>
								</td>
								<td>&nbsp;</td>
							</tr>
						</tbody>
						<tfoot class="total">
							<tr>
								<td class="svc">
									<span class="txt" v-if="isSAAS">결제 금액(VAT포함)</span>
									<span class="txt" v-else>계약 금액(VAT포함)</span>
								</td>
								<td class="sum" colspan="5">
									<span class="txt">{{ summaryInfo.totPayAmt | currency }}원</span>
								</td>
							</tr>
							<tr class="refund" v-if="summaryInfo.totRefundAmt > 0">
								<td class="svc">
									<span class="txt">환불 금액(VAT포함)</span>
								</td>
								<td class="sum" colspan="5">
									<span class="txt">-{{ summaryInfo.totRefundAmt | currency }}원</span>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<!--기존결제금액 - 환불금액 = 최종결제금액 : 계산식-->
			<div v-if="summaryInfo.totRefundAmt > 0" class="com_calculation">
				<div class="item">
					<span class="tit">
						<span class="txt" v-if="isSAAS">기존 결제 금액 (VAT포함)</span>
						<span class="txt" v-else>기존 계약 금액 (VAT포함)</span>
					</span>
					<span class="data"
						><span class="txt">{{ summaryInfo.totPayAmt | currency }}원</span></span
					>
				</div>
				<div class="symbol">
					<span class="tit"><span class="ic_set ic_calc_minus"></span></span>
				</div>
				<div class="item">
					<span class="tit"><span class="txt">환불 금액 (VAT포함)</span></span>
					<span class="data"
						><span class="txt">{{ summaryInfo.totRefundAmt | currency }}원</span></span
					>
				</div>
				<div class="symbol">
					<span class="tit"><span class="ic_set ic_calc_equal"></span></span>
				</div>
				<div class="item">
					<span class="tit">
						<span class="txt" v-if="isSAAS">최종 결제 금액 (VAT포함)</span>
						<span class="txt" v-else>최종 계약 금액 (VAT포함)</span>
					</span>
					<span class="data">
						<span class="txt">{{ summaryInfo.finalTotPayAmt | currency }}원</span>
					</span>
				</div>
			</div>
		</div>

		<!--//container : 환불정보-->
		<div class="wrap_function" v-on:click="goListPage()">
			<button class="major">
				<span class="txt">목록</span>
			</button>
		</div>

		<!--layer : 환불 내역 상세-->
		<div
			v-if="cancelInfoPopup.flag"
			class="com_layer_normal layer_refund_detail"
			style="position: absolute; left: 30%; top: 30%; z-index: 9998px"
		>
			<div class="lay_header">
				<h1>환불 내역</h1>
				<a class="btn_close" title="닫기" @click="cancelInfoPopup.flag = false"
					><span class="ic_set ic_close"></span
				></a>
			</div>
			<!--content-->
			<div class="lay_body">
				<div class="com_form">
					<div class="wrap_row">
						<div class="row">
							<label class="form_tit cell">
								<span class="txt">상품명</span>
							</label>
							<div class="form_data cell">
								<span
									v-if="cancelInfoPopup.itemRefund.itemName.indexOf('경리회계') > -1"
									class="ic_svc ic_accounting"
								></span>
								<span v-if="cancelInfoPopup.itemRefund.itemName == '경비처리(무증빙)'" class="ic_svc ic_bizplay"></span>
								<span v-if="cancelInfoPopup.itemRefund.itemName == 'TMSe 장비일체형 S'" class="ic_svc ic_tmse_s"></span>
								<span v-if="cancelInfoPopup.itemRefund.itemName == 'TMSe 장비일체형 E'" class="ic_svc ic_tmse_e"></span>
								<span
									v-if="cancelInfoPopup.itemRefund.itemName == 'TMSe 장비일체형 T'"
									class="ic_svc ic_tmse_share"
								></span>
								<span class="txt">{{ cancelInfoPopup.itemRefund.itemName }}</span>
							</div>
						</div>
					</div>
					<div class="wrap_row">
						<div class="row">
							<label class="form_tit cell">
								<span class="txt" v-if="isSAAS">결제 금액(VAT포함)</span>
								<span class="txt" v-else>계약 금액(VAT포함)</span>
							</label>
							<div class="form_data cell">
								<span class="txt">{{ parseInt(cancelInfoPopup.itemRefund.totPayAmt) | currency }}원</span>
							</div>
						</div>
					</div>
					<div class="wrap_row">
						<div class="row">
							<label class="form_tit cell">
								<span class="txt">사용기간</span>
							</label>
							<div class="form_data cell">
								<span class="txt">{{ cancelInfoPopup.itemRefund.usingPeriod }}</span>
							</div>
						</div>
					</div>
					<div class="wrap_row">
						<div class="row">
							<label class="form_tit cell">
								<span class="txt">사용금액(VAT포함)</span>
							</label>
							<div class="form_data cell">
								<span class="txt">{{ cancelInfoPopup.itemRefund.usingAmt | currency }}원</span>
							</div>
						</div>
					</div>
					<div class="wrap_row">
						<div class="row">
							<label class="form_tit cell">
								<span class="txt">환불기간</span>
							</label>
							<div class="form_data cell">
								<span class="txt">{{ cancelInfoPopup.itemRefund.refundPeriod }}</span>
							</div>
						</div>
					</div>
					<div class="wrap_row">
						<div class="row sum">
							<!--.sum class 추가-->
							<label class="form_tit cell">
								<span class="txt">환불금액(VAT포함)</span>
							</label>
							<div class="form_data cell">
								<span class="txt">{{ cancelInfoPopup.itemRefund.refundAmt | currency }}원</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--//content end-->
			<div class="lay_bottom">
				<div class="wrap_function">
					<button class="major" v-on:click="cancelInfoPopup.flag = false">확인</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ConvertPaymethod from '@/components/apply/user/store/ConvertPaymethod.vue';
const payment = {
	TRANSPORT: '무통장입금',
	CREDITCARD: '신용카드',
	REALTIME_TRANSPORT: '실시간 계좌이체',
	ADMIN_DIRECT_PAYMENT: '관리자 결제',
	MOBILE: '모바일 결제',
	AUTO_CREDITCARD: '자동 카드결제',
};
const service = {
	SAAS: '공유형',
	VPC: '단독형',
	ON_PREMISE: '설치형',
	OFF_PREMISE: '구축형',
	TMS: 'TMS',
	TMSE: 'TMSE',
	TMW: 'TMW',
	TMA: 'TMA',
};
export default {
	data() {
		return {
			contractInfo: null,
			payHistoryModel: null,
			orderMonthCount: 0,
			discountInfos: [],
			discountDesc: '',
			payMethodInfo: {},
			save: false,
			headerInfo: {},
			orderInfo: null,
			orderData: [],
			summaryInfo: {},
			pageEntity: {
				total: 0, // 전체 Row수
				perPage: 10,
			},
			keyword: '',
			serviceType: [],
			status: [],
			cancelInfoPopup: {
				itemRefund: null,
				flag: false,
			},
			isSAAS: true,
		};
	},
	created() {
		const id = this.$route.params.id;
		this.getOrder(id);
	},
	methods: {
		async popupSales() {
			if (!this.contractInfo) {
				this.$ms.dialog.alert('계약정보 없음', '계약정보가 없습니다.');
				return;
			}
			if (!this.payHistoryModel) {
				this.$ms.dialog.alert('결제정보 없음', '결제정보가 없습니다.');
				return;
			}
			const popupInfo = {
				salesSlip: `https://agent.daoupay.com/common/PayInfoPrintDirectCard.jsp?DAOUTRX=${this.payHistoryModel.daouTrx}`,
			};
			window.open(popupInfo['salesSlip']);
		},
		async getContractInfoByCode(orderCode) {
			try {
				const { data } = await this.$store.dispatch('GET_CONTRACT_INFO', orderCode);

				this.contractInfo = data;
				this.payHistoryModel = data.payHistoryModel;

				return data;
			} catch (e) {
				console.log(e);
			}
		},
		numberWithCommas(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
		getServiceName(code) {
			return service[code];
		},
		async cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
		async openConvertPaymethod() {
			this.$modal.show(
				ConvertPaymethod,
				{
					orderInfo: this.orderInfo,
					totPayAmt: this.summaryInfo.finalTotPayAmt ? this.summaryInfo.finalTotPayAmt : this.summaryInfo.totPayAmt,
				},
				{
					width: '800px',
					height: '750px',
					draggable: true,
				},
				{
					closed: this.closeConvertPaymethod,
				},
			);
		},
		async closeConvertPaymethod() {
			const id = this.$route.params.id;
			this.getOrder(id);
		},
		async setPayMethodInfo(payMethodInfo) {
			this.payMethodInfo = payMethodInfo;
		},
		goListPage() {
			this.$router.push(`/user/myStore/OrderHistory`);
		},
		async getOrder(id) {
			try {
				const data = await this.$store.dispatch('GETORDER', id);
				this.orderInfo = data;
				this.isSAAS = data.serviceType === 'SAAS';
				this.orderMonthCount = data.orderMonthCount;
				await this.$store.commit('setUsePeriod', this.orderMonthCount);

				if (this.orderInfo.orderStatus == 'COMPLETE') {
					await this.getContractInfoByCode(this.orderInfo.code + '-CONT');
				}
				const baseChannelModel = this.orderInfo.baseChannelOrderInfoModel
					? this.orderInfo.baseChannelOrderInfoModel.baseChannelModel
					: null;
				this.$store.commit('setBasicItemSelected', baseChannelModel);
				await this.setOrderData(this.orderInfo);
			} catch (error) {
				console.log(error);
			}
		},
		async setSummaryData(data, orderData) {
			let totRefundAmt = 0;
			await orderData.reduce((previous, current) => {
				return previous.then(async () => {
					await current.reduce((previous2, current2) => {
						return previous2.then(async () => {
							if (current2.itemRefund) {
								totRefundAmt += current2.itemRefund.refundAmt;
							}
						});
					}, Promise.resolve());
				});
			}, Promise.resolve());

			return {
				totAmt: (data.amount / 11) * 10,
				totPayAmt: data.amount,
				vatAmt: (data.amount / 11) * 1,
				totRefundAmt: totRefundAmt,
				finalTotPayAmt: data.amount - parseInt(totRefundAmt),
			};
		},
		async getFormatDate(date) {
			let year = date.getFullYear();
			let month = 1 + date.getMonth();
			month = month >= 10 ? month : '0' + month;
			let day = date.getDate();
			day = day >= 10 ? day : '0' + day;
			return year + '-' + month + '-' + day;
		},
		async setOrderData(data) {
			const addStartDate = await this.getFormatDate(new Date(data.orderDate));
			let extensionEndDate = data.orderEndDate;
			const addTempDate = new Date(extensionEndDate);
			const orderType = data.baseInfoModel.orderType;

			addTempDate.setMonth(addTempDate.getMonth() - parseInt(data.orderMonthCount));
			let addEndDate = await this.getFormatDate(addTempDate);
			const extensionTempDate = new Date(addTempDate);

			extensionTempDate.setDate(extensionTempDate.getDate() + (orderType === 'ADD_EXTENSION' ? 1 : 0));

			let extensionStartDate = await this.getFormatDate(extensionTempDate);

			if (!this.orderInfo.hasEndDate) {
				extensionStartDate = addStartDate;
				extensionEndDate = '만료일 없음';
				addEndDate = '만료일 없음';
			}
			// data = orderInfoModel
			const discountInfos = [];
			var table = [];
			let services = [];

			if (data.baseChannelOrderInfoModel) {
				await services.push(data.baseChannelOrderInfoModel);
			}

			if (data.addonChannelOrderInfoModels && data.addonChannelOrderInfoModels.length > 0) {
				await data.addonChannelOrderInfoModels.reduce((previous, addonChannel) => {
					return previous.then(async () => {
						await services.push(addonChannel);
					});
				}, Promise.resolve());
			}

			if (services.length == 0) return;
			// 목록에 출력할 데이터 list setting
			await services.reduce((previous, service) => {
				return previous.then(async () => {
					let serviceName = '';
					if (service.baseChannelModel) {
						serviceName = service.baseChannelModel.name;
					} else if (service.addonChannelModel) {
						serviceName = service.addonChannelModel.name;
					}

					let serviceTable = [];
					if (service.defaultFeeOrderDetail) {
						let row = {};
						row.serviceName = serviceName;
						row.itemName = '기본료';
						row.count = '';
						//row.status = service.defaultFeeOrderDetail.status;
						row.freeTrialOrderType = '';
						if (service.freeTrialOrderType) {
							row.freeTrialOrderType = service.freeTrialOrderType;
						}
						row.type = 'main';

						// test
						/*
						row.status = 'CANCELED';

						service.defaultFeeOrderDetail.itemCancelInfoModel = {
							cancelDate: '2021-05-30',
							refundPrice: 100000,
							refundPaymentId: 1,
							refundType: 'PART_REFUND',
						};*/
						if (service.defaultFeeOrderDetail.itemCancelInfoModel) {
							const itemRefund = await this.setCancelInfoData(
								serviceName,
								service.defaultFeeOrderDetail.finalPrice,
								service.applyDate,
								service.defaultFeeOrderDetail.itemCancelInfoModel,
							);

							row.status = 'CANCELED';
							row.itemRefund = itemRefund;
						}

						if (orderType == 'FRESH') {
							let addRow = await this.cloneObj(row);
							addRow.usePeriod = extensionStartDate + '~' + extensionEndDate;
							addRow.finalPrice = service.defaultFeeOrderDetail.finalPrice;
							addRow.addExtension = '신규';

							await serviceTable.push(addRow);
						} else if (orderType == 'ADD_EXTENSION') {
							if (service.defaultFeeOrderDetail.addPrice > 0) {
								let addRow = await this.cloneObj(row);

								addRow.usePeriod = this.$moment(addStartDate).format('YYYY-MM-DD') + '~' + addEndDate;
								addRow.addExtension = '추가';
								addRow.finalPrice = service.defaultFeeOrderDetail.addPrice;
								await serviceTable.push(addRow);
							}

							if (service.defaultFeeOrderDetail.extensionPrice > 0) {
								let extensionRow = await this.cloneObj(row);

								extensionRow.usePeriod = extensionStartDate + '~' + extensionEndDate;
								extensionRow.addExtension = '연장';
								extensionRow.finalPrice = service.defaultFeeOrderDetail.extensionPrice;
								await serviceTable.push(extensionRow);
							}
						} else {
							let addRow = await this.cloneObj(row);
							addRow.addExtension =
								orderType == 'ADD' ? '추가' : orderType == 'EXTENSION' ? '연장' : orderType == 'CHANGE' ? '변경' : '';
							addRow.usePeriod =
								orderType == 'ADD' || orderType == 'CHANGE'
									? this.$moment(addStartDate).format('YYYY-MM-DD') + '~' + addEndDate
									: orderType == 'EXTENSION'
									? extensionStartDate + '~' + extensionEndDate
									: '';
							addRow.finalPrice = service.defaultFeeOrderDetail.finalPrice;
							await serviceTable.push(addRow);
						}
						if (service.defaultFeeOrderDetail.discountInfoModels.length > 0) {
							await service.defaultFeeOrderDetail.discountInfoModels.forEach((obj) => {
								if (obj.discountPrice > 0 || obj.discountRate > 0) {
									const input =
										serviceName +
										' ' +
										obj.discountName +
										' ' +
										(obj.discountPrice > 0
											? this.numberWithCommas(obj.discountPrice) + '원'
											: obj.discountRate > 0
											? this.numberWithCommas(obj.discountRate) + '%'
											: '');

									if (discountInfos.findIndex((obj) => obj == input) == -1) {
										discountInfos.push(input);
									}
								}
							});
						}
					}

					if (service.addUserFeeOrderDetails !== undefined && service.addUserFeeOrderDetails.length > 0) {
						let addUserCnt = 0;
						let addUserAmt = 0;
						let extensionUserCnt = 0;
						let extensionUserAmt = 0;

						let totUserCnt = 0;
						let totUserAmt = 0;
						if (service.addUserFeeOrderDetails) {
							await service.addUserFeeOrderDetails.reduce((previousObj, obj) => {
								return previousObj.then(async () => {
									addUserCnt += parseInt(obj.addCount);
									addUserAmt += parseInt(obj.addPrice);
									extensionUserCnt += parseInt(obj.existingCount) + parseInt(obj.addCount);
									extensionUserAmt += parseInt(obj.extensionPrice);
									totUserCnt += parseInt(obj.totalOrderCount ? obj.totalOrderCount : 0);
									totUserAmt += parseInt(obj.finalPrice);
								});
							}, Promise.resolve());
						}
						if (orderType == 'FRESH') {
							if (totUserCnt > 0) {
								// 추가 사용자
								let row = {};
								row.serviceName = serviceName;
								row.itemName = '사용자 추가';
								if (service.freeTrialOrderType) {
									row.freeTrialOrderType = service.freeTrialOrderType;
								}
								row.status = '';
								row.count = totUserCnt + '명';

								row.usePeriod = extensionStartDate + '~' + extensionEndDate;
								row.finalPrice = totUserAmt;
								row.originPrice = totUserAmt;
								row.type = 'main';
								row.addExtension = '신규';

								await serviceTable.push(row);

								if (service.addUserFeeOrderDetails) {
									await service.addUserFeeOrderDetails.reduce((previousObj, obj) => {
										return previousObj.then(async () => {
											let subRow = {};

											subRow.status = obj.status;
											subRow.serviceName = serviceName;
											subRow.itemName = obj.itemName;
											subRow.count = (obj.totalOrderCount ? obj.totalOrderCount : 0) + '명';
											subRow.usePeriod = '';
											subRow.type = 'sub';

											if (obj.itemCancelInfoModel) {
												const itemRefund = await this.setCancelInfoData(
													serviceName,
													obj.finalPrice,
													service.applyDate,
													obj.itemCancelInfoModel,
												);
												subRow.status = 'CANCELED';
												subRow.itemRefund = itemRefund;
											}

											if (obj.finalPrice > 0) {
												let addRow = await this.cloneObj(subRow);
												addRow.finalPrice = obj.finalPrice + '원';
												await serviceTable.push(addRow);
											}
										});
									}, Promise.resolve());
								}
							}
						} else if (orderType == 'ADD_EXTENSION') {
							if (addUserCnt > 0) {
								// 추가 사용자
								let row = {};
								row.serviceName = serviceName;
								row.addExtension = '추가';
								row.itemName = '사용자 추가';
								if (service.freeTrialOrderType) {
									row.freeTrialOrderType = service.freeTrialOrderType;
								}
								row.status = '';
								row.count = addUserCnt + '명';

								row.usePeriod = this.$moment(addStartDate).format('YYYY-MM-DD') + '~' + addEndDate;
								row.finalPrice = addUserAmt;
								row.originPrice = addUserAmt;
								row.type = 'main';
								await serviceTable.push(row);

								if (service.addUserFeeOrderDetails) {
									await service.addUserFeeOrderDetails.reduce((previousObj, obj) => {
										return previousObj.then(async () => {
											let subRow = {};

											subRow.status = obj.status;
											subRow.serviceName = serviceName;
											subRow.itemName = obj.itemName;
											subRow.count = obj.addCount + '명';
											subRow.usePeriod = '';
											subRow.type = 'sub';

											if (obj.itemCancelInfoModel) {
												const itemRefund = await this.setCancelInfoData(
													serviceName,
													obj.finalPrice,
													service.applyDate,
													obj.itemCancelInfoModel,
												);
												subRow.status = 'CANCELED';
												subRow.itemRefund = itemRefund;
											}

											if (obj.addPrice > 0) {
												let addRow = await this.cloneObj(subRow);
												addRow.finalPrice = obj.addPrice + '원';
												await serviceTable.push(addRow);
											}
										});
									}, Promise.resolve());
								}
							}

							if (extensionUserCnt > 0) {
								// 연장 사용자
								let row = {};
								row.serviceName = serviceName;
								row.addExtension = '연장';
								row.itemName = '사용자 추가';
								if (service.freeTrialOrderType) {
									row.freeTrialOrderType = service.freeTrialOrderType;
								}
								row.status = '';
								row.count = extensionUserCnt + '명';
								row.usePeriod = extensionStartDate + '~' + extensionEndDate;
								row.finalPrice = extensionUserAmt;
								row.originPrice = extensionUserAmt;
								row.type = 'main';
								await serviceTable.push(row);

								if (service.addUserFeeOrderDetails) {
									await service.addUserFeeOrderDetails.reduce((previousObj, obj) => {
										return previousObj.then(async () => {
											let subRow = {};

											subRow.status = obj.status;
											subRow.serviceName = serviceName;
											subRow.itemName = obj.itemName;
											subRow.count = parseInt(obj.existingCount) + parseInt(obj.addCount) + '명';
											subRow.usePeriod = '';
											subRow.type = 'sub';

											if (obj.itemCancelInfoModel) {
												const itemRefund = await this.setCancelInfoData(
													serviceName,
													obj.finalPrice,
													service.applyDate,
													obj.itemCancelInfoModel,
												);
												subRow.status = 'CANCELED';
												subRow.itemRefund = itemRefund;
											}

											let extensionRow = await this.cloneObj(subRow);
											extensionRow.finalPrice = obj.extensionPrice + '원';
											await serviceTable.push(extensionRow);
										});
									}, Promise.resolve());
								}
							}
						} else {
							if (totUserCnt > 0) {
								// 추가 사용자
								let row = {};
								row.serviceName = serviceName;
								row.addExtension =
									orderType == 'ADD' ? '추가' : orderType == 'EXTENSION' ? '연장' : orderType == 'CHANGE' ? '변경' : '';
								row.itemName = '사용자 추가';
								if (service.freeTrialOrderType) {
									row.freeTrialOrderType = service.freeTrialOrderType;
								}
								row.status = '';
								row.count = totUserCnt + '명';
								row.usePeriod =
									orderType == 'ADD' || orderType == 'CHANGE'
										? this.$moment(addStartDate).format('YYYY-MM-DD') + '~' + addEndDate
										: orderType == 'EXTENSION'
										? extensionStartDate + '~' + extensionEndDate
										: '';

								row.finalPrice = totUserAmt;
								row.originPrice = totUserAmt;
								row.type = 'main';
								await serviceTable.push(row);

								if (service.addUserFeeOrderDetails) {
									await service.addUserFeeOrderDetails.reduce((previousObj, obj) => {
										return previousObj.then(async () => {
											let subRow = {};

											subRow.status = obj.status;
											subRow.serviceName = serviceName;
											subRow.itemName = obj.itemName;
											subRow.count = obj.totalOrderCount + '명';
											subRow.usePeriod = '';
											subRow.type = 'sub';

											if (obj.itemCancelInfoModel) {
												const itemRefund = await this.setCancelInfoData(
													serviceName,
													obj.finalPrice,
													service.applyDate,
													obj.itemCancelInfoModel,
												);
												subRow.status = 'CANCELED';
												subRow.itemRefund = itemRefund;
											}

											if (obj.finalPrice > 0) {
												let addRow = await this.cloneObj(subRow);
												addRow.finalPrice = obj.finalPrice + '원';
												await serviceTable.push(addRow);
											}
										});
									}, Promise.resolve());
								}
							}
						}

						if (service.addUserFeeOrderDetails) {
							// 사용자 추가 할인 확인
							await service.addUserFeeOrderDetails.reduce((previousObj, obj) => {
								return previousObj.then(async () => {
									if (obj.discountInfoModels.length > 0) {
										await obj.discountInfoModels.forEach((info) => {
											if (info.discountPrice > 0 || info.discountRate > 0) {
												const input =
													serviceName +
													' ' +
													info.discountName +
													' ' +
													(info.discountPrice > 0
														? this.numberWithCommas(info.discountPrice) + '원'
														: info.discountRate > 0
														? this.numberWithCommas(info.discountRate) + '%'
														: '');

												if (discountInfos.findIndex((obj) => obj == input) == -1) {
													discountInfos.push(input);
												}
											}
										});
									}
								});
							}, Promise.resolve());
						}
					}
					if (service.membershipFeeOrderDetail) {
						let row = {};
						row.serviceName = serviceName;
						row.itemName = '설치비';
						row.freeTrialOrderType = '';
						if (service.freeTrialOrderType) {
							row.freeTrialOrderType = service.freeTrialOrderType;
						}

						row.status = service.membershipFeeOrderDetail.status;
						row.count = '1회';
						row.type = 'main';

						if (service.membershipFeeOrderDetail.itemCancelInfoModel) {
							const itemRefund = await this.setCancelInfoData(
								serviceName,
								service.membershipFeeOrderDetail.finalPrice,
								service.applyDate,
								service.membershipFeeOrderDetail.itemCancelInfoModel,
							);
							row.status = 'CANCELED';
							row.itemRefund = itemRefund;
						}

						if (orderType == 'FRESH') {
							if (service.membershipFeeOrderDetail.finalPrice > 0) {
								let addRow = await this.cloneObj(row);
								addRow.usePeriod = extensionStartDate + '~' + extensionEndDate;
								addRow.finalPrice = service.membershipFeeOrderDetail.finalPrice;
								addRow.addExtension = '신규';
								await serviceTable.push(addRow);
							}
						} else if (orderType == 'ADD_EXTENSION') {
							if (service.membershipFeeOrderDetail.addPrice > 0) {
								let addRow = await this.cloneObj(row);
								addRow.usePeriod = this.$moment(addStartDate).format('YYYY-MM-DD') + '~' + addEndDate;
								addRow.addExtension = '추가';
								addRow.finalPrice = service.membershipFeeOrderDetail.addPrice;
								await serviceTable.push(addRow);
							}

							if (service.membershipFeeOrderDetail.extensionPrice > 0) {
								let extensionRow = await this.cloneObj(row);
								extensionRow.usePeriod = extensionStartDate + '~' + extensionEndDate;
								extensionRow.addExtension = '연장';
								extensionRow.finalPrice = service.membershipFeeOrderDetail.extensionPrice;
								await serviceTable.push(extensionRow);
							}
						} else {
							if (service.membershipFeeOrderDetail.finalPrice > 0) {
								let addRow = await this.cloneObj(row);
								addRow.addExtension =
									orderType == 'ADD' ? '추가' : orderType == 'EXTENSION' ? '연장' : orderType == 'CHANGE' ? '변경' : '';
								addRow.usePeriod =
									orderType == 'ADD' || orderType == 'CHANGE'
										? this.$moment(addStartDate).format('YYYY-MM-DD') + '~' + addEndDate
										: orderType == 'EXTENSION'
										? extensionStartDate + '~' + extensionEndDate
										: '';
								addRow.finalPrice = service.membershipFeeOrderDetail.finalPrice;
								await serviceTable.push(addRow);
							}
						}
						if (service.membershipFeeOrderDetail.discountInfoModels.length > 0) {
							// 할인 내역
							await service.membershipFeeOrderDetail.discountInfoModels.forEach((obj) => {
								if (obj.discountPrice > 0 || obj.discountRate > 0) {
									const input =
										serviceName +
										' ' +
										obj.discountName +
										' ' +
										(obj.discountPrice > 0
											? this.numberWithCommas(obj.discountPrice) + '원'
											: obj.discountRate > 0
											? this.numberWithCommas(obj.discountRate) + '%'
											: '');

									if (discountInfos.findIndex((obj) => obj == input) == -1) {
										discountInfos.push(input);
									}
								}
							});
						}
					}

					if (service.itemOrderDetails && service.itemOrderDetails.length > 0) {
						await service.itemOrderDetails.reduce((previousOption, currentOption) => {
							return previousOption.then(async () => {
								let row = {};
								row.serviceName = serviceName;
								row.billingType = currentOption.billingType;
								row.itemName = currentOption.channelItemSimpleModel.displaySectionModel.displayName;
								if (service.freeTrialOrderType) {
									row.freeTrialOrderType = currentOption.freeTrialOrderType;
								}
								row.count = currentOption.totalOrderCount + '개';

								row.status = currentOption.status;
								row.type = 'main';
								if (currentOption.itemCancelInfoModel) {
									const itemRefund = await this.setCancelInfoData(
										serviceName,
										currentOption.finalPrice,
										service.applyDate,
										currentOption.itemCancelInfoModel,
									);
									row.status = 'CANCELED';
									row.itemRefund = itemRefund;
								}

								if (orderType == 'FRESH') {
									let addRow = await this.cloneObj(row);
									addRow.usePeriod = extensionStartDate + '~' + extensionEndDate;
									addRow.finalPrice = currentOption.finalPrice;
									addRow.addExtension = '신규';

									await serviceTable.push(addRow);
								} else if (orderType == 'ADD_EXTENSION') {
									if (currentOption.addPrice > 0) {
										let addRow = await this.cloneObj(row);
										addRow.usePeriod = this.$moment(addStartDate).format('YYYY-MM-DD') + '~' + addEndDate;
										addRow.addExtension = '추가';

										addRow.finalPrice =
											addRow.billingType == 'ONCE' ? currentOption.finalPrice : currentOption.addPrice;
										await serviceTable.push(addRow);
									}

									if (currentOption.extensionPrice > 0) {
										let extensionRow = await this.cloneObj(row);
										extensionRow.usePeriod = extensionStartDate + '~' + extensionEndDate;
										extensionRow.addExtension = '연장';
										extensionRow.finalPrice = currentOption.extensionPrice;
										await serviceTable.push(extensionRow);
									}
								} else {
									let addRow = await this.cloneObj(row);
									addRow.addExtension =
										orderType == 'ADD'
											? '추가'
											: orderType == 'EXTENSION'
											? '연장'
											: orderType == 'CHANGE'
											? '변경'
											: '';
									addRow.usePeriod =
										orderType == 'ADD' || orderType == 'CHANGE'
											? this.$moment(addStartDate).format('YYYY-MM-DD') + '~' + addEndDate
											: orderType == 'EXTENSION'
											? extensionStartDate + '~' + extensionEndDate
											: '';
									addRow.finalPrice = currentOption.finalPrice;
									await serviceTable.push(addRow);
								}
								if (currentOption.discountInfoModels.length > 0) {
									// 할인
									await currentOption.discountInfoModels.forEach((obj) => {
										if (obj.discountPrice > 0 || obj.discountRate > 0) {
											const input =
												serviceName +
												' ' +
												obj.discountName +
												' ' +
												(obj.discountPrice > 0
													? this.numberWithCommas(obj.discountPrice) + '원'
													: obj.discountRate > 0
													? this.numberWithCommas(obj.discountRate) + '%'
													: '');
											if (discountInfos.findIndex((obj) => obj == input) == -1) {
												discountInfos.push(input);
											}
										}
									});
								}
							});
						}, Promise.resolve());
					}
					table.push(serviceTable);
				});
			}, Promise.resolve());
			this.discountInfos = discountInfos;
			for (let i = 0; i < discountInfos.length; i++) {
				this.discountDesc += discountInfos[i] + '<br>';
			}

			this.orderData = table;

			console.log(this.orderData);
			this.headerInfo = await this.setHeaderData(data, this.orderData); // 하단 summary 데이터
			this.summaryInfo = await this.setSummaryData(data, this.orderData); // 하단 summary 데이터
			return table;
		},
		async getRemainDays(startDate) {
			// api 호출

			const { data } = await this.$store.dispatch('GETREMAINDAYS', {
				membershipId: this.orderInfo.membershipModel.id,
				startDate: startDate,
			});

			const remainMonth = data.remainMonth;
			const remainDay = data.remainDay;

			return {
				remainMonth,
				remainDay,
			};
		},
		async setHeaderData(data, orderData) {
			let payStatus = 'NONE';

			await orderData.reduce((previous, current) => {
				return previous.then(async () => {
					await current.reduce((previous2, current2) => {
						return previous2.then(async () => {
							if (current2.itemRefund) {
								payStatus = current2.itemRefund.refundType;
							}
						});
					}, Promise.resolve());
				});
			}, Promise.resolve());

			return {
				paymentDate: data.payType == 'TRANSPORT' ? data.baseInfoModel.orderDate : data.baseInfoModel.paymentDate,
				paymenter: '',
				payMethod: payment[data.payType],
				payType: data.payType,
				payStatus: payStatus,
				orderStatus: data.orderStatus,
				orderType: data.orderType,
				contractDate: data.contractDate,
				applyDate: data.applyDate,
				pageOrderInfoTitle: this.isSAAS ? '주문/구매 정보' : '주문/계약 정보',
				pageContractTitle: this.isSAAS ? '결제 정보' : '계약 정보',
			};
		},
		async setCancelInfoData(serviceName, totPayAmt, serviceStartDate, itemRefund) {
			const remainDays = await this.getRemainDays(itemRefund.cancelDate);
			const vatTotPayAmt = parseInt(totPayAmt) * 1.1;
			const obj = {
				itemName: serviceName,
				totPayAmt: vatTotPayAmt,
				usingPeriod: serviceStartDate + '~' + itemRefund.cancelDate,
				usingAmt:
					parseInt(vatTotPayAmt) < parseInt(itemRefund.refundPrice)
						? 0
						: parseInt(vatTotPayAmt) - parseInt(itemRefund.refundPrice),
				refundPeriod:
					remainDays.remainMonth > 0
						? remainDays.remainDay > 0
							? remainDays.remainMonth + '개월 ' + remainDays.remainDay + '일'
							: remainDays.remainMonth + '개월'
						: remainDays.remainDay > 0
						? remainDays.remainDay + '일'
						: '', //api연결예정
				refundAmt: itemRefund.refundPrice,
				refundType: itemRefund.refundType,
				cancelDate: itemRefund.cancelDate,
			};

			return obj;
		},
	},
};
</script>

<style></style>
