<template>
	<div>
		<div class="com_layer_normal layer_organogram" style="width: 800px; height: 900px !important">
			<div class="lay_header">
				<h1>결제방법 변경</h1>
				<a class="btn_close" title="닫기" v-on:click="close()"><span class="ic_set ic_close"></span></a>
			</div>
			<!--content-->
			<div class="lay_body vue_component doms_overflow">
				<pay-method
					:saveFlag="saveFlag"
					v-on:setPayMethodInfo="setPayMethodInfo"
					:orderType="orderInfo.baseInfoModel.orderType"
				></pay-method>
				<div class="lay_bottom">
					<div class="wrap_function">
						<span class="txt">
							선택하신 결제 방법으로 해당 계약건이 변경 결제 됩니다.
							<p>결제 방법을 변경하시겠습니까?</p>
						</span>
						<br />
						<button class="major" v-on:click="save()">변경</button>
						<button class="major" v-on:click="close()">취소</button>
					</div>
				</div>
				<!--//content end-->
			</div>
		</div>
	</div>
</template>

<script>
const paymentConvert = {
	DEPOSIT: 'TRANSPORT',
	CREDITCARD: 'CREDITCARD',
	REALTIMETRANSPORT: 'REALTIME_TRANSPORT',
	AUTOEXTENSION: 'AUTO_CREDITCARD',
};
const paymentDesc = {
	DEPOSIT: '무통장입금',
	CREDITCARD: '신용카드',
	REALTIMETRANSPORT: '실시간 계좌이체',
	AUTOEXTENSION: '자동카드결제',
};
import PayMethod from '@/components/payment/method/PayMethod.vue';
import PayRequestLayer from '@/components/payment/PayRequestLayer.vue';
import { payment } from '@/components/apply/mixinsPayment.js';
export default {
	mixins: [payment],
	props: {
		orderInfo: {},
		totPayAmt: Number,
	},
	data() {
		return {
			saveFlag: false,
			paymentUrl: '/kiwoom/popup',
		};
	},
	components: {
		PayMethod,
	},
	methods: {
		setPayMethodInfo(payMethodInfo) {
			this.saveFlag = false;

			this.payMethodInfo = payMethodInfo;
			this.payMethodInfo['amount'] = this.totPayAmt;
			this.payMethodInfo['orderId'] = this.orderInfo.id;
			this.payMethodInfo['orderSummary'] = this.orderInfo.orderSummary;

			this.convertPayType(this.payMethodInfo);
			if (payMethodInfo.payType == 'DEPOSIT') {
				this.close();
			} else if (payMethodInfo.payType == 'AUTOEXTENSION') {
				if (!this.checkAutoExtension(this.payMethodInfo)) {
					return;
				}
				// 자동결제 정보 저장
				this.saveAutoExtensionInfo(this.payMethodInfo, this.orderInfo);
			} else {
				this.goPayment(this.payMethodInfo);
			}

			return;
		},
		async convertPayType(payMethodInfo) {
			if (this.orderInfo.payType == paymentConvert[payMethodInfo.payType]) {
				return;
			}
			this.orderInfo.payType = paymentConvert[payMethodInfo.payType];
			await this.$store.dispatch('CHANGEPAYTYPE', this.orderInfo);
		},
		async goPayment(payMethodInfo) {
			await this.openPayRequestLayer(payMethodInfo);
			await window.addEventListener(
				'message',
				(event) => {
					if (event.data === 'success') {
						this.close();
					} else if (event.data === 'close') {
						this.$ms.dialog.alert('결제 중 오류가 발생하였습니다.', () => {});
					}
				},
				false,
			);
		},

		async saveAutoExtensionInfo(paymethodInfo, order) {
			const result = await this.setAutoExtension(paymethodInfo, order.id, order.customer.id); // 자동결제 등록
			this.saveFlag = false;
			if (result) {
				this.close();
			}
		},

		save() {
			this.saveFlag = true;
		},
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style scoped>
.com_layer_normal {
	z-index: 999;
}
#popupLayer {
	z-index: 9999;
}
.doms_overflow {
	height: 600px;
	overflow-y: scroll;
}
</style>
